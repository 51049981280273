.must-watch {
    padding: 53px 0 65px;

    .container {
        >h2 {
            color: $dark;
            @include fontsize(24px);
            font-family:  $general-sans-medium;
            font-weight: 500;
            margin-bottom: 31px;
            text-align: center;

            @include for-size(laptop-view) {
                @include fontsize(30px);
                margin-bottom: 20px;
            }
        }
    }
    &.must-buy{
        display: none;
        @include for-size(tablet-view){
            display: block;
        }
        .container{
            >h2{
                text-align: left;
            }
        }
    }
}
.category-carousel-wrapper{
    .icon-arrow{
        top: 37%;
    }
}