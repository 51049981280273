.blog-block {
    margin-bottom: 30px;
    position: relative;

    @include for-size(laptop-view) {
        margin-bottom: 43px;
    }

    .container {
        padding-right: 0;
    }

    .heading-wrap {
        display: flex;
        justify-content: center;
        align-items: baseline;
        margin-bottom: 7px;
        padding-right: 15px;

        @include for-size(tablet-view) {
            justify-content: space-between;
            padding-right: 0;
        }

        @include for-size(tabletLarge-view) {
            margin-bottom: 22px;
        }

        h2 {
            @include fontsize(30px);
            font-family: $general-sans-regular;
            font-weight: 400;
        }

        a {
            color: #F49D1A;
            text-decoration: underline;
            @include fontsize(14px);
            display: none;

            &:hover {
                color: $dark;
            }

            @include for-size(tablet-view) {
                display: block;
                @include fontsize(20px);
            }
        }
    }
}

.blog-wrap {
    @include for-size(tabletLarge-view) {
        display: flex;
        margin-top: 23px;
    }

    &::before {
        @include for-size(tabletLarge-view) {
            content: '';
            position: absolute;
            top: 91px;
            width: calc(100% + 241px);
            left: -100%;
            height: calc(100% - 91px);
            background-color:  $white;
        }
    }
    .blog {
        position: relative;
        img {
            width: 100%;
            display: block;
        }
        .blog-content {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 20px 14px;
            h2 {
                @include fontsize(18px);
                color:  $white;
                font-weight: 500;
                line-height: 1.4;
                margin-bottom: 11px;
            }
            a {
                color: $yellow;
                @include fontsize(14px);
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $yellow;
                    transition: all 0.5s;
                }
                &:hover{
                    &::after{
                        width: 0;
                    }
                }
            }
        }
    }
}
.blog-link {
    color: $orange;
    text-decoration: underline;
    @include fontsize(20px);
    display: block;
    width: max-content;
    margin: 8px auto 0;
    @include for-size(tablet-view){
        display: none;
    }
}