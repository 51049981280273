.product-tabbing {
    .container {
        padding-right: 0;
    }
}

ul {
    &.tabs-navigation,
    &.tabs {
        margin-bottom: 9px;
        padding-bottom: 10px;
        list-style: none;
        display: flex;
        overflow-x: auto;

        @include for-size(tablet-view) {
            justify-content: center;
        }

        li {
            background: none;
            display: inline-block;
            white-space: nowrap;
            @include fontsize(22px);
            margin-right: 40px;

            a {
                color: $text-color;
            }

            &.ui-state-active,
            &.current {
                a {
                    color: $pink;
                    font-family: $general-sans-medium;
                    font-weight: 500;
                }
            }
        }
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }
    }
}

.tab-content {
    display: none;

    &.current {
        display: inherit;
    }
}

.category-carousel-wrapper {
    .slide {
        text-align: center;
    }
}

.products-slick,
.widget-product-carousel {
    .slick-list {
        margin: 0 -7px;
    }

    .slick-slide {
        margin: 0 7px;
    }
}

.product-item,
.product-card {
    background-color: $white;
    text-align: left;
    padding: 0px 16px 16px 16px;

    @include for-size(tabletLarge-view) {
        padding: 10px 16px 16px 16px;
    }

    img {
        transition: all 0.5s;
        margin: 0 auto;
        display: block;
    }

    .product-item-name,
    h2 {
        @include fontsize(16px);
        line-height: 1.38;
        font-weight: 400;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        min-height: 66px;
        margin-bottom: 12px;

        &:hover {
            a {
                color: #073919;
            }
        }

        a {
            color: $dark;
        }
    }

    .price-box {
        /* display: flex;
        flex-direction: column-reverse; */
        min-height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 16px;
    }

    .cancel-price {
        color: #797979;
        font-family: $satoshi-regular;
        @include fontsize(14px);
        display: block;
        font-weight: 400;
        margin-bottom: 5px;
    }

    .normal-price,
    .special-price,
    .current-price {
        color: #dc3535;
        font-family: $satoshi-regular;
        @include fontsize(20px);
        font-weight: 700;
        display: block;
    }

    .reviews-actions {
        display: none !important;
    }

    .action.tocart,
    .add-cart-btn {
        background-color: $white;
        color: $dark;
        @include fontsize(15px);
        font-family: $general-sans-semibold;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-radius: 2px;
        border: 1px solid $dark;
        padding: 17px 9px;
        letter-spacing: -0.4px;
        transition: all 0.5s;

        span {
            &::before {
                content: "";
                @extend .icon-minicart;
                width: 24px;
                height: 24px;
                background-size: contain;
                margin-right: 8px;
                filter: invert(1);

                @include media-breakpoint-up(lg) {
                    filter: none;;
                }
            }
        }

        i,
        svg {
            margin-right: 8px;
        }

        &:hover {
            background-color: $green;
            color: $white;
            border-color: $green;

            i,
            svg,
            span::before {
                filter: invert(100%) contrast(100%);
            }
        }
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
    }
}

.product-item-photo,
.product-image {
    overflow: hidden;
    display: block;
    width: 100%;
    text-align: center;
}

.product-tags {
    margin-bottom: 6px;
    min-height: 24px;

    .tag {
        @include fontsize(12px);
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        padding: 0 8px;
        display: inline-block;
        margin-right: 8px;
        font-family: $satoshi-regular;

        &.blue {
            background-color: #cff4ff;
            color: #265570;
        }

        &.orange {
            color: #9a7004;
            background-color: #ffebce;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.product-rating {
    min-height: 19.19px;
    margin-bottom: 17px;
}

.icon-star {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(../images/star.png) 0 0 no-repeat;
    background-size: cover;

    &.null {
        background: url(../images/star-null.png) 0 0 no-repeat;
    }
}

.swiper-container {
    img {
        width: 100%;
        display: block;
    }
}

.rating-summary {
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 17px;

    .rating-result {
        width: 88px;
        display: inline-block;
        position: relative;
        vertical-align: middle;

        &::before {
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
            -webkit-font-smoothing: antialiased;
            color: #d8d8d8;
            font-family: luma-icons;
            font-size: 16px;
            height: 16px;
            letter-spacing: 2px;
            line-height: 16px;
            content: "\2605""\2605""\2605""\2605""\2605";
            display: block;
            font-style: normal;
            font-weight: 400;
        }

        > span {
            display: block;
            overflow: hidden;

            &:before {
                position: relative;
                z-index: 2;
                -webkit-font-smoothing: antialiased;
                color: #efcf28;
                font-family: luma-icons;
                font-size: 16px;
                height: 16px;
                letter-spacing: 2px;
                line-height: 16px;
                content: "\2605""\2605""\2605""\2605""\2605";
                display: block;
                font-style: normal;
                font-weight: 400;
                //speak: none
            }

            span {
                @extend %hide;
            }
        }
    }
}

%hide {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.product-reviews-summary .rating-summary .label,
.table-reviews .rating-summary .label {
    @extend %hide;
}
