.cart {
    margin-top: 23px;

    @include for-size(laptop-view) {
        margin-bottom: 90px;
    }

    h1 {
        @include fontsize(22px);
        margin-bottom: 20px;
        font-weight: 400;
        text-align: center;

        @include for-size(laptop-view) {
            @include fontsize(30px);
            margin-bottom: 30px;
        }
    }

    .quantity-block {
        @include for-size(tablet-view) {
            width: 110px;
            border-radius: 2px;
            border: 1px solid $dark;
            display: flex;
            align-items: center;
            height: 40px;
            justify-content: space-between;
        }

        button {
            @include for-size(tablet-view) {
                background-color: transparent;
                border: none;
                flex: none;
                text-align: center;
                width: 30px;
            }
        }

        input {
            @include for-size(tablet-view) {
                text-align: center;
                @include fontsize(14px);
            }
        }
    }

    .zip-code-wrap {
        display: block;
        margin-bottom: 25px;
    }

    .cart-value {
        padding-top: 22px;
    }

    .choose-more {
        color: #7B7B7B;
        text-align: center;
        @include fontsize(16px);
        text-decoration-line: underline;

        @include for-size(tabletLarge-view) {
            display: none;
        }
        &:hover{
            color: $pink;
        }
    }

}

.cart-wrap {
    @include for-size(tabletLarge-view) {
        display: flex;
        align-items: start;
    }

    .product-cart {
        padding: 25px 26px 46px 108px;
        background-color:  $white;
    }

    .left-block {
        max-height: 100vh;
        overflow-y: auto;

        @include for-size(tablet-view) {
            background-color:  $white;
        }

        @include for-size(tabletLarge-view) {
            width: 100%;
            margin-right: 22px;
        }

        @include for-size(laptop-view) {
            max-height: 867px;
            overflow-y: auto;
        }
    }

    .right-block {
        padding: 20px 0 22px;
        background-color: $white;

        @include for-size(tabletLarge-view) {
            width: 356px;
            flex: none;
            padding: 19px 20px;
        }

    }
}

.cart table {
    width: 100%;

    @include for-size(tablet-view) {
        table-layout: fixed;
    }

    thead {
        @include for-size(tablet-view) {
            position: sticky;
            top: 0;
            left: 0;
            background-color: $white;
            z-index: 9;
        }
    }
    .product-item-details{
        padding-right: 10px;
    }
}

.cart-head {
    display: none;

    @include for-size(tablet-view) {
        display: table-row;
    }

    th {
        @include for-size(tablet-view) {
            @include fontsize(16px);
            font-family: $satoshi-medium;
            font-weight: 700;
            display: table-cell;
            text-align: left;
            padding: 18px 0;
            position: sticky;
            top: 0;
            left: 0;
            background: $white;
            z-index: 9;
        }

        @include for-size(tabletLarge-view) {
            top: 15px;
        }

        &:first-child {
            @include for-size(tablet-view) {
                padding-left: 20px;
            }

            @include for-size(laptop-view) {
                width: 100px;
            }
        }

        &.product_name {
            @include for-size(tablet-view) {
                width: 230px;
            }

            @include for-size(tabletLarge-view) {
                width: 205px;
            }

            @include for-size(laptop-view) {
                width: 200px;
            }

            @include for-size(laptopLarge-view) {
                width: 300px;
            }
        }

        &.blank {
            @include for-size(tablet-view) {
                display: none;
            }

            @include for-size(laptop-view) {
                display: table-cell;
            }
        }

        &.valor {
            @include for-size(tablet-view) {
                display: none;
            }

            @include for-size(laptop-view) {
                display: table-cell;
            }
        }

        &.quant {
            @include for-size(tabletLarge-view) {
                width: 130px;
            }
        }

        &.dlt {
            @include for-size(tablet-view) {
                width: 75px;
            }

            @include for-size(tabletLarge-view) {
                width: 50px;
            }

            @include for-size(laptop-view) {
                width: 90px;
            }
        }
    }
}

.product-cart {
    position: relative;
    display: block;
    margin-bottom: 12px;

    @include for-size(tablet-view) {
        display: table-row;
    }

    .image-wrapper {
        min-width: 65px;
        text-align: center;
        position: absolute;
        top: 25px;
        left: 15px;
        padding: 0;

        @include for-size(tablet-view) {
            position: static;
            display: table-cell;
            padding: 14px 0 25px;
        }

        @include for-size(laptop-view) {
            padding: 15px 0 41px;
        }
    }

    h2 {
        @include fontsize(14px);
        color: #404040;
        font-family: $satoshi-regular;
        margin: 14px 0 12px;
        font-weight: 400;
        line-height: 1.5;
        padding-right: 28px;

        @include for-size(tablet-view) {
            margin: 0;
            width: 193px;
            padding-right: 0;
        }

        @include for-size(desktop-view) {
            width: 251px;
            @include fontsize(16px);
        }
    }

    td {
        display: block;

        @include for-size(tablet-view) {
            display: table-cell;
            width: auto;
            vertical-align: middle;
        }

        &.calculater {
            display: none;

            @include for-size(laptop-view) {
                display: table-cell;
                @include fontsize(16px);
                color: #9D9D9D;
            }
        }
    }

    .price-cart {
        display: none;

        @include for-size(laptop-view) {
            display: table-cell;
            @include fontsize(14px);
            color: #313131;
        }

        @include for-size(desktop-view) {
            @include fontsize(16px);
        }
    }

    .select-quant {
        display: none;

        @include for-size(tablet-view) {
            display: table-cell;
        }
    }

    .current-price {
        color: #DC3535;
        @include fontsize(16px);
        font-family: $general-sans-medium;
        font-weight: 500;
        margin-bottom: 12px;
        display: block;

        @include for-size(tablet-view) {
            margin-bottom: 0;
        }
    }

    select {
        width: 75px;
        height: 40px;
        display: flex;
        align-items: center;
        @include fontsize(14px);
        font-family: $general-sans-regular;
        padding: 0 13px;
        border-radius: 1px;
        border: 1px solid #292929;
    }
}

.delete-cart {
    position: absolute;
    border: none;
    background-color: transparent;
    top: 17px;
    right: 22px;
    width: max-content;

    @include for-size(tablet-view) {
        position: static;
    }
}

.quant-dropdown {
    @include for-size(tablet-view) {
        display: none !important;
    }
}

.zip-code-text {
    color: $dark;
    margin-top: 17px;
    font-family: $general-sans-semibold;
    white-space: nowrap;
    margin-right: 15px;
    @include fontsize(16px);
    margin-bottom: 6px;
    display: block;
}

.zip-code-wrap {
    input:not([type=radio]), select {
        color: $dark;
        @include fontsize(14px);
        padding: 15px 29px;
        border-radius: 2px;
        margin-bottom: 8px;
        border: 1px solid #8A8A8A;
        max-width: 100%;
    }

    .zip-wrap {
        a {
            color: #979797;
            @include fontsize(12px);
            text-decoration-line: underline;

            &:hover {
                color: $pink;
            }
        }
    }
}

.delivery-services {
    padding-bottom: 32px;

    h4 {
        @include fontsize(14px);
        font-weight: 500;
        font-family: $general-sans-medium;
    }
}

.delivery-checkbox {
    input[type="radio"] {
        appearance: none;

        &+label {
            cursor: pointer;
            position: relative;
            padding-left: 25px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 16px;
                width: 16px;
                border-radius: 50%;
                border: 1px solid #5C5C5C;
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                height: 16px;
                width: 16px;
                background: url(../images/checkbox-2.png) 0 0 no-repeat;
                background-size: cover;
                display: none;
            }
        }

        &:checked~label {
            font-weight: 500;
            font-family: $general-sans-medium;

            &::after {
                display: block;
            }
        }
    }

    label {
        color: #616161;
        @include fontsize(16px);
    }
}

.apply-coupen {
    padding: 20px 0 24px;
    border-bottom: 1px solid #C3C3C3;
    border-top: 1px solid #C3C3C3;

    h4 {
        @include fontsize(14px);
        font-weight: 500;
        font-family: $general-sans-medium;
        margin-bottom: 6px;
    }
}

.coupen-input {
    position: relative;

    input {
        padding: 15px 85px 15px 13px;
        border-radius: 2px;
        border: 1px solid $dark;
    }

    button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: $green;
        @include fontsize(12px);
        font-weight: 600;
        border: none;
        background-color: transparent;
        right: 22px;

        &:hover {
            color: #073919;
        }
    }
}

.cart-value {
    th {
        @include fontsize(16px);
        color: #4A4A4A;
        line-height: 1.8;
        text-align: left;
        font-weight: 400;
        background-color: transparent;
    }

    td {
        @include fontsize(16px);
        color: #4A4A4A;
        line-height: 1.8;
        text-align: left;
        font-weight: 400;
        &:last-child {
            text-align: right;
        }
        &.discount {
            color: $green;
        }
    }
    button {
        padding: 17px 20px;
        margin-top: 10px;
        width: 100%;
        background-color: $green;
        border: 1px solid transparent;
        color:  $white;
        @include fontsize(20px);
        font-family: $general-sans-medium;
        font-weight: 500;
        transition: all 0.5s;
        justify-content: center;
        display: flex;
        align-items: center;
        margin-bottom: 37px;
        @include for-size(tabletLarge-view){
            margin-bottom: 10px;
        }
        &:hover {
            border-color: $dark;
            background-color: transparent;
            color: $dark;
            svg{
                filter: invert(100%);
            }
        }
        svg{
            margin-right: 10px;
        }
    }
}
