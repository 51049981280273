html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}
*,
:before,
:after {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    outline: none;
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    scroll-behavior: smooth;
}

body {
    font-size: 100%;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "general_sansregular", sans-serif;
    background-color: #fbfbfb;
    color: $text-color;
    &.hidden {
        overflow: hidden;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            z-index: 99;
            width: 100%;
            background: linear-gradient(
                rgba(0, 0, 0, 0.75),
                rgba(0, 0, 0, 0.75)
            );
        }
    }
    img {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    }
}
img {
    max-width: 100%;
    height: auto;
}
*:focus {
    outline: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
// *::-webkit-scrollbar-track {
//   background-color: transparent;
// }

// *::-webkit-scrollbar {
//   width: 0;
//   height: 0;
//   background-color: transparent;
// }

// *::-webkit-scrollbar-thumb {
//   background-color: transparent;
// }
/*
 2.0.1) Font Face
 -----------------------------*/
@font-face {
    font-family: "general_sansbold";
    src: url("../fonts/generalsans-bold-webfont.woff2") format("woff2"),
        url("../fonts/generalsans-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "general_sansextralight";
    src: url("../fonts/generalsans-extralight-webfont.woff2") format("woff2"),
        url("../fonts/generalsans-extralight-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "general_sanslight";
    src: url("../fonts/generalsans-light-webfont.woff2") format("woff2"),
        url("../fonts/generalsans-light-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "general_sansmedium";
    src: url("../fonts/generalsans-medium-webfont.woff2") format("woff2"),
        url("../fonts/generalsans-medium-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "general_sansregular";
    src: url("../fonts/generalsans-regular-webfont.woff2") format("woff2"),
        url("../fonts/generalsans-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "general_sanssemibold";
    src: url("../fonts/generalsans-semibold-webfont.woff2") format("woff2"),
        url("../fonts/generalsans-semibold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "satoshibold";
    src: url("../fonts/satoshi-bold-webfont.woff2") format("woff2"),
        url("../fonts/satoshi-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "satoshilight";
    src: url("../fonts/satoshi-light-webfont.woff2") format("woff2"),
        url("../fonts/satoshi-light-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "satoshimedium";
    src: url("../fonts/satoshi-medium-webfont.woff2") format("woff2"),
        url("../fonts/satoshi-medium-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "satoshiregular";
    src: url("../fonts/satoshi-regular-webfont.woff2") format("woff2"),
        url("../fonts/satoshi-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

/*
 2.0.3) Form Elements
 -----------------------------*/
input {
    margin: 0;
    padding: 0;
    @include fontsize(14px);
    color: inherit;
    background: transparent;
    border: 1px solid transparent;
    width: 100%;
    line-height: normal;
}

strong,
b {
    font-weight: bold;
}

em {
    font-style: italic;
    margin-bottom: 10px;
}

blockquote {
    line-height: 1.2;
    margin-bottom: 16px;
}

a {
    text-decoration: none;
    transition: all 0.5s;
    display: inline-block;
}

a:focus {
    outline: none;
    border: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: 0;
}

input[type="checkbox"],
input[type="radio"] {
    padding: 0;
    border: none;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"],
input[type="file"] {
    cursor: pointer;
    line-height: normal;
    overflow: visible;
}

button[disabled],
input[disabled] {
    cursor: default;
    color: #999;
    border-color: #ccc;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
.slick-arrow,
.icon-arrow {
    position: absolute;
    top: 44%;
    transform: translateY(-50%);
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 0;
    border: none;
    line-height: 32px;
    transition: all 0.5s;
    background-image: none;
}
.slick-prev,
.swiper-button-prev {
    background: url(../images/arrow-left.svg) 50% 50% no-repeat #f5f5f5;
    left: 10px;
    z-index: 1;
}
.slick-next,
.swiper-button-next {
    background: url(../images/arrow-right.svg) 50% 50% no-repeat #f5f5f5;
    right: 10px;
}
.swiper-pagination {
    position: absolute;
    bottom: 24px;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    width: max-content;
    list-style: none;
}
.swiper-pagination-bullet {
    margin-right: 7px;
    border: none;
    height: 12px;
    width: 12px;
    border-radius: 6px;
    background-color: #d9d9d9;
    opacity: 1;
    transition: all 0.5s;
    font-size: 0;
    &:last-child {
        margin-right: 0;
    }
}
.swiper-pagination-bullet-active {
    width: 35px;
    background-color: #f49d1a;
}
.swiper-button-next {
    &.swiper-button-disabled {
        pointer-events: all;
    }
}
.swiper-button-prev {
    &.swiper-button-disabled {
        pointer-events: all;
    }
}

/* .container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 15px;
    @include for-size(large-desktop-view) {
        padding: 0;
    }
}
 */
p {
    @include fontsize(16px);
    line-height: 1.4;
}
