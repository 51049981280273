.nutritional-information {
    .container {
        padding: 0;

        @include for-size(tablet-view) {
            padding: 0 15px;
        }
    }

    h2 {
        color: $dark;
        @include fontsize(24px);
        font-family: $general-sans-medium;
        font-weight: 500;
        margin-bottom: 34px;
        text-align: center;

        @include for-size(tablet-view) {
            text-align: left;
        }

        @include for-size(laptop-view) {
            @include fontsize(30px);
        }

        a {
            color: $dark;
            text-decoration: none;
        }
    }

    &.border {
        border: 0 !important;        
        @include for-size(laptop-view) {
            padding: 0 0 25px;
            margin-top: 41px;
        }

        .container {
            border-top: 1px solid $dark;
            padding-top: 56px;

            @include for-size(laptop-view) {
                padding-top: 66px;
            }
        }
    }
}

.nutrition-table {
    @include fontsize(14px);
    width: 100%;
    margin-bottom: 11px;
    max-width: 548px;

    @include for-size(tablet-view) {
        @include fontsize(16px);
    }

    th {
        &:last-child {
            text-align: center;
        }
    }

    th,
    td {
        padding: 13px 14px;
        border: none;
        text-align: left;

        @include for-size(tablet-view) {
            padding: 13px 7px;
        }
    }

    tr {
        &:nth-child(even) {
            background-color: #f2eae1;
        }
        td {
            &:last-child {
                text-align: center;
            }
        }
    }
}

.nutrition-weight {
    display: flex;
    @include fontsize(16px);
    font-family: $general-sans-semibold;
    padding: 0 14px;
    margin-bottom: 65px;

    @include for-size(tablet-view) {
        @include fontsize(18px);
        padding-left: 6px;
    }

    .pro-name {
        margin-right: 39px;

        @include for-size(tablet-view) {
            margin-right: 25px;
        }
    }
}

.border-heading {
    padding: 29px 0;
    border-top: 1px solid $dark;
    border-bottom: 1px solid $dark;

    @include for-size(laptop-view) {
        padding: 62px 0;
    }
}

.nutri-content-wrapper {
    @include for-size(tabletLarge-view) {
        display: flex;
        justify-content: space-between;
    }

    .left-block {
        padding: 0 15px;

        @include for-size(tablet-view) {
            padding: 0;
        }

        @include for-size(tabletLarge-view) {
            width: 457px;
            margin-right: 45px;
            flex: none;
        }

        h2 {
            text-align: left;
            margin-bottom: 22px;

            @include for-size(tabletLarge-view) {
                margin-bottom: 46px;
            }
        }

        p {
            @include fontsize(16px);
            margin-bottom: 60px;
            line-height: 1.8;

            @include for-size(tabletLarge-view) {
                @include fontsize(18px);
            }
        }
    }

    .nutri-content {
        @include for-size(tabletLarge-view) {
            max-width: 548px;
            width: 100%;
        }

        p {
            display: none;

            @include for-size(tablet-view) {
                display: block;
                font-weight: 500;
                font-family: "general_sansmedium", sans-serif;
                @include fontsize(18px);
                padding-left: 7px;
            }
        }
    }
}
