/*
 Font-family
 -------------------------*/
$general-sans-bold: "general_sansbold", sans-serif;
$general-sans-extralight: "general_sansextralight", sans-serif;
$general-sans-light: "general_sanslight", sans-serif;
$general-sans-medium: "general_sansmedium", sans-serif;
$general-sans-semibold: "general_sanssemibold", sans-serif;
$general-sans-regular: "general_sansregular", sans-serif;
$satoshi-light: "satoshilight", sans-serif;
$satoshi-medium: "satoshimedium", sans-serif;
$satoshi-regular: "satoshiregular", sans-serif;
/*
 Font-color
 -------------------------*/
$white: #ffffff;
$dark: #000000;
$text-color: #181818;
$yellow: #FFE15D;
$orange: #F49D1A;
$pink: #B01E68;
$green: #3FBF5B;
/*
 Font-weight
 -------------------------*/
$fw-thin: 100;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 700;

/*
 Alignment
 -------------------------*/
$align-stretch: stretch;
$align-center: center;
$align-start: flex-start;
$align-end: flex-end;
$align-end: end;
/*
 justify
 -------------------------*/
$justify-start: flex-start;
$justify-end: flex-end;
$justify-center: center;
$justify-between: space-between;
$justify-around: space-around;
/*
 text alignment
 -------------------------*/
$text-left: left;
$text-center: center;
$text-right: right;

/*
 flex
 -------------------------*/
$d-flex: flex;
$d-wrap: wrap;
$d-column: column;
$d-row: row;
$row-reverse: row-reverse;
$column-reverse: column-reverse;
