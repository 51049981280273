.newsletter {
}

.newsletter-wrapper {
    background-color: $yellow;
    padding: 25px 20px;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;

    @include for-size(laptop-view) {
        margin-bottom: 35px;
    }

    @include for-size(tabletLarge-view) {
        display: flex;
        justify-content: space-between;
        text-align: left;
        padding: 46px 41px 33px 41px;
    }

    .left-block {
        margin-bottom: 20px;

        @include for-size(laptop-view) {
            margin: 0;
            width: 50%;
            padding-right: 60px;
        }

        h2 {
            @include fontsize(24px);
            margin: 0 auto 7px;
            width: 300px;

            @include for-size(tablet-view) {
                width: auto;
                margin: 0 0 7px 0;
            }

            @include for-size(laptop-view) {
                @include fontsize(30px);
            }
        }

        p {
            @include fontsize(16px);
            text-align: left;

            @include for-size(laptop-view) {
                @include fontsize(20px);
            }
        }
    }

    .right-block {
        padding-bottom: 0;
        @include for-size(laptop-view) {
            margin: 0;
            width: 50%;
            max-width: 600px;
            margin-left: auto;

            .input-wrapper {
                width: 100%;
                margin: 0 auto 9px;

                @include for-size(laptop-view) {
                    margin: 0;
                }
            }
        }

        p {
            text-align: center;
            @include fontsize(14px);

            @include for-size(tablet-view) {
                text-align: left;
            }

            @include for-size(laptop-view) {
                @include fontsize(14px);
            }
        }
    }

    .input-wrap {
        display: flex;
        margin-bottom: 32px;
        flex-direction: column;
        justify-content: right;

        @include for-size(tablet-view) {
            flex-direction: row;
            margin-bottom: 12px;
        }
    }

    .input-space {
        position: relative;
        width: 100%;
        margin-bottom: 8px;

        @include for-size(tablet-view) {
            margin-bottom: 0;
        }

        svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 16px;
        }
    }

    input {
        border: 1px solid $dark;
        padding: 21px 16px 21px 50px;
        color: #aeaeae;
        background-color: $white;

        @include for-size(laptop-view) {
            padding: 22px 16px 21px 50px;
        }
    }

    .btn {
        width: 213px;
        background-color: transparent;
        border: 1px solid $dark;
        color: $dark;
        flex: none;
        padding: 20px;
        border-radius: 2px;
        margin-left: auto;
        font-family: $general-sans-medium;
        @include fontsize(18px);
        transition: all 0.5s;

        @include for-size(tablet-view) {
            margin-left: 15px;
        }

        @include for-size(laptop-view) {
            width: 199px;
            @include fontsize(18px);
            font-weight: 500;
            padding: 20px;
        }
        &:hover {
            border-color: $dark;
            background-color: $dark;
            color: $white;
        }
    }
}


@include builder("column-group", "newsletter-wrapper") {
    @extend .newsletter-wrapper;
}