.product-filter {
    margin-bottom: 40px;

    @include for-size(tabletLarge-view) {
        margin-bottom: 60px;
        background-color: #fbfbfb;
        overflow-x: hidden;
    }

    @include for-size(desktop-view) {
        margin-bottom: 93px;
    }
}

.filter-wrapper {
    @include for-size(tabletLarge-view) {
        display: flex;
        position: relative;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        width: 200%;
        height: 100%;
        background-color: #fff;
        left: -200%;
    }
}

.filter-sidebar {
    position: fixed;
    top: 0;
    left: -400px;
    height: 100vh;
    width: 375px;
    background-color: $white;
    transition: left 0.5s;
    z-index: 9;

    &.active {
        left: 0;
        z-index: 100;
    }

    @include for-size(tabletLarge-view) {
        position: relative;
        flex: none;
        box-sizing: content-box;
        border-right: 15px solid #FBFBFB;
        display: block;
        left: 0;
        height: auto;
    }

    @include for-size(desktop-view) {
        width: 353px;
        border-width: 29px;
    }
}

.close-filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 19px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    z-index: 1;

    @include for-size(tabletLarge-view) {
        display: none;
    }

    h2 {
        color: $dark;
        @include fontsize(20px);
        font-family: $general-sans-semibold;
    }
}

.filter-close {
    background-color: transparent;
    border: none;

    &::before {
        content: '';
        display: block;
        background-image: url('../images/close.png');
        background-repeat: no-repeat;
        width: 32px;
        height: 32px;
    }
}

.filter-listing {
    list-style: none;
    height: 100%;
    overflow-y: auto;
    padding-top: 73px;
    padding-bottom: 117px;

    @include for-size(tabletLarge-view) {
        padding-top: 0;
        padding-bottom: 0;
        overflow: inherit;
    }

    &:first-child {
        display: none;

        @include for-size(tabletLarge-view) {
            display: block;
        }
    }

    .listing-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 11px 19px;
        border-bottom: 1px solid $dark;

        &.active {
            border-bottom: 0;

            .open-listing {
                transform: rotate(180deg);
            }
        }

        .listing_heading {
            color: $dark;
            font-family: $general-sans-medium;
            @include fontsize(16px);
            font-weight: 600;
        }
    }
}

.open-listing {
    background-color: transparent;
    border: none;
    transition: all 0.5s;

    &::before {
        content: '';
        display: block;
        background-image: url('../images/open-listing.png');
        background-repeat: no-repeat;
        width: 32px;
        height: 32px;
    }
}

.dashed-line {
    border-top: 1px dashed #c3c3c3;
    margin: 0 auto 7px;
}

.filter-content {
    padding: 0 19px 35px;
    display: none;
    border-bottom: 1px solid $dark;

    .view-more {
        color: #DC3535;
        text-decoration: underline;
        border: none;
        background-color: transparent;
        @include fontsize(14px);
        font-family: $general-sans-medium;

        &:hover {
            color: #000;
        }
    }
}

.category-list {
    list-style: none;
    padding-top: 10px;

    li {
        margin-bottom: 22px;

    /*     &:first-child {
            display: none;

            @include for-size(tabletLarge-view) {
                display: block;
            }
        }
 */
        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        color: $dark;
        @include fontsize(16px);

        &:hover {
            color: #DC3535;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.search-filter {
    border-radius: 4px;
    border: 1px solid $dark;
    position: relative;
    margin-bottom: 16px;

    input {
        color: #3B3B3B;
        @include fontsize(14px);
        padding: 14px 100px 14px 18px;
    }

    .search-btn {
        background-color: transparent;
        border: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
    }
}

.checkbox-listing {
    list-style: none;

    li {
        margin-bottom: 22px;
    }

    input[type="checkbox"] {
        display: none;

        &:checked+label {
            color: #DC3535;

            &::after {
                display: block;
            }
        }
    }

    .checkbox_icon {
        color: $dark;
        @include fontsize(16px);
        position: relative;
        padding-left: 35px;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 25px;
            width: 25px;
            background-color: #EBEBEB;
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            height: 25px;
            width: 25px;
            background: url(../images/checkbox.png) 0 0 no-repeat;
            background-size: cover;
            display: none;
        }
    }
}

.sidebar-bottom-wrap {
    padding: 29px 17px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;

    @include for-size(tabletLarge-view) {
        position: static;
    }

    .delete-filter {
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        @include fontsize(16px);
        color: $dark;

        &::before {
            content: '';
            display: block;
            background-image: url('../images/clear.png');
            background-repeat: no-repeat;
            width: 26px;
            height: 24px;
            margin-right: 14px;
        }

    }

    .filter-btn {
        color: $dark;
        @include fontsize(18px);
        background-color: $yellow;
        border: 1px solid $yellow;
        border-radius: 8px;
        width: 169px;
        padding: 19px 10px;

        &:hover {
            background-color: transparent;
            color: $yellow;
        }
    }
}

.product-header-wrap {
    @include for-size(tabletLarge-view) {
        width: 100%;
        position: relative;
    }
}

.filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: $white;
    margin-bottom: 20px;

    @include for-size(tabletLarge-view) {
        margin-bottom: 0;
        background-color: #FBFBFB;
    }

    .filter-type-btn,
    button {
        background-color: transparent;
        border: none;
        width: max-content;
        display: flex;
        align-items: center;
        color: #101010;
        @include fontsize(16px);
        font-family: $general-sans-medium;
    }

    .filter-btn {
        &::before {
            content: '';
            display: block;
            background-image: url('../images/filtro.png');
            background-repeat: no-repeat;
            width: 25px;
            height: 18px;
            margin-right: 8px;
        }

        @include for-size(tabletLarge-view) {
            display: none;
        }
    }
}

.total-products {
    color: #292929;
    font-family: $satoshi-regular;
    @include fontsize(14px);
    display: none;

    @include for-size(tabletLarge-view) {
        display: block;
    }

    .products-count {
        font-family: $satoshi-medium;
        font-weight: 700;
        margin-right: 7px;
    }
}

.filter-type {
    @include for-size(desktop-view) {
        display: flex;
        align-items: center;
        color: #292929;
        @include fontsize(14px);
    }

    button {
        display: none;

        @include for-size(desktop-view) {
            display: block;
        }
    }

    .order-btn {
        display: flex;

        &::before {
            content: '';
            display: block;
            background-image: url('../images/order.png');
            background-repeat: no-repeat;
            width: 22px;
            height: 22px;
        }

        @include for-size(desktop-view) {
            display: none;
        }
    }

    .filter-text {
        display: none;

        @include for-size(desktop-view) {
            margin-right: 20px;
            display: block;
        }
    }
}

.filter-type-btn {
    @include for-size(desktop-view) {
        margin-right: 13px;
        line-height: 2;

        &.active {
            font-family: $general-sans-semibold;
        }
    }
}

.filter-view {
    display: flex;

    button {
        &.three-column-view {
            display: none;

            @include for-size(laptop-view) {
                display: block;
            }
        }

        &.four-column-view {
            display: none;

            @include for-size(desktop-view) {
                display: block;
            }
        }

        &.two-column-view {
            @include for-size(laptop-view) {
                display: none;
            }
        }

        &.active {
            svg {
                fill: $yellow;
            }
        }
    }
}

.filter-products-wrapper {
    display: grid;
    gap: 8px;
    background-color: #FBFBFB;
    margin-bottom: 50px;
    list-style: none;

    @include for-size(tabletLarge-view) {
        gap: 16px;
        margin-bottom: 17px;
    }

    &.single-view {
        .product-card {
            margin: 0;
            padding: 10px 13px;
            display: flex;

            @include for-size(desktop-view) {
                justify-content: space-between;
                padding: 8px 23px;
            }

            .product-image {
                width: 37.3%;
                flex: none;

                @include for-size(desktop-view) {
                    width: 12.8%;
                }
            }

            h2 {
                min-height: auto;
            }

            .product-rating {
                @include for-size(desktop-view) {
                    display: none;
                }
            }

            .product-information {
                width: 100%;

                @include for-size(desktop-view) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }

            .add-cart-btn {
                @include for-size(desktop-view) {
                    width: 238px;
                }
            }

            .price-information {
                @include for-size(desktop-view) {
                    width: 18%;
                }
            }
        }
    }

    .product-card {
        .add-cart-btn {
            color: $white;
            border: 1px solid $dark;
            background-color: $green;
            border-color: $green;
            padding: 11px 9px;
            @include for-size(tabletLarge-view){
                color: $dark;
                background-color: transparent;
                border-color: $dark;
            }
            svg {
                filter: invert(0);
                @include for-size(tabletLarge-view){
                    filter: invert(100%);
                }
            }

            &:hover {
                color: $dark;
                border: 1px solid $dark;
                background-color: transparent;
                @include for-size(tabletLarge-view){
                    background-color: $green;
                    border-color: $green;
                    color: $white;
                }
                svg {
                    filter: invert(100%);
                    @include for-size(tabletLarge-view){
                        filter: invert(0);
                    }
                }
            }
        }
    }
}

.single-view {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;

    .tag-and-name {
        @include for-size(desktop-view) {
            width: 296px;
        }
    }
}

.double-view {
    grid-template-columns: repeat(2, 1fr);

    @include for-size(laptop-view) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.triple-view {
    grid-template-columns: repeat(3, 1fr);
}

.four-view {
    grid-template-columns: repeat(4, 1fr);
}
.filter-paging {
    
}
.filter-btn-wrap {
    .pages{
        display: none;
    }
    .view-more, .swissup-layered-more-products {
    
    
        margin-top:50px;
        display: block;
        border: 1px solid $dark;
        @include fontsize(18px);
        color: $dark;
        text-align: center;
        padding: 16px;
        transition: all 0.5s;
        max-width: 345px;
        margin: 0 auto 33px;
        width: 100%;
        cursor: pointer;
        background-color: trasparent;
        &:hover {
            border-color: $white;
            color: $white;
            background-color: $dark;
        }

        @include for-size(tabletLarge-view) {
            max-width: 196px;
            margin-top: 17px;
        }
    }
}

.brands-information {
    padding: 29px 17px;
    background-color: #F7F4E4;
    color: #4A442B;

    @include for-size(laptop-view) {
        padding: 56px 54px 42px;
    }

    p {
        @include fontsize(14px);
        line-height: 1.35;
    }

    h3 {
        @include fontsize(14px);
        font-family: $general-sans-bold;
        line-height: 1.5;
    }

    a {
        color: #4A442B;
        text-decoration: underline;
        transition: 0.5s;

        &:hover {
            color: #DC3535;
        }
    }
}

//actions
.order-block.is-dropdown {
    .order-btn {
        display: flex;
        @include for-size(desktop-view) {
            display: none;
        }

        &::after {
            right: -10px;
        }
    }
}

.filter-type.desktop {
    display: none;
    @include for-size(desktop-view) {
        display: flex;
    }
}

.filter-label {
    font-weight: 700;
    &::after {
        content: ": ";
    }
}

.filter-current {
    padding-left: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid;

    @include for-size(tabletLarge-view) {
        border-bottom: none;
        padding-top: 20px;
    }

    ol, ul {
        list-style: none;
    }

    .filter-current-subtitle {
        margin-bottom: 1rem;
        display: inline-block;
    }

    .item {
        position: relative;
        padding-left: 20px;
        margin-bottom: 5px;
    }

    .action {
        &.remove {
            position: absolute;
            left: 0;
            
            &::before {
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 17px;
                line-height: 15px;
                color: #757575;
                content: '\2715';
                vertical-align: middle;
                display: inline-block;
                font-weight: 400;
                overflow: hidden;
                text-align: center;
            }

            span {
                @extend %hide;
            }
        }
    }
}
