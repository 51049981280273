.product-category2 {
    margin-bottom: 35px;
    @include for-size(tablet-view){
        margin-bottom: 64px;
    }
    .image-wrapper {
        background-color: $yellow;
        border-radius: 10px;
        margin-bottom: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 160px;
        img{
            width: auto;
        }
    }
    .slide {
        .slide_heading {
            color: $dark;
            @include fontsize(18px);
            font-weight: 400;
            text-transform: capitalize;
            text-align: center;
        }
    }
}