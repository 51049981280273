.product-view {
    text-align: center;
    margin-bottom: 41px;
    @include for-size(desktop-view) {
        margin-bottom: 80px;
    }
    h1 {
        @include fontsize(30px);
        margin-bottom: 11px;
        font-weight: 400;
    }
    p {
        color: #787878;
        @include fontsize(14px);
        line-height: 1.5;
        margin: 0 auto 35px;
        max-width: 923px;
    }
    &.desktop {
        display: none;
        @include for-size(tabletLarge-view) {
            display: block;
        }
    }
}
.product_wrapper {
    overflow: hidden;
}
.product-wrap {
    text-align: left;
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
    margin: 0 -8px;
    a {
        display: block;
        margin: 0 8px;
        max-width: 160px;
        text-align: center;
        h2 {
            color: $dark;
            @include fontsize(16px);
            font-weight: 600;
            text-transform: capitalize;
            text-align: center;
        }
    }
    .image-wrapper {
        background-color: $yellow;
        border-radius: 10px;
        margin-bottom: 17px;
        display: flex;
        justify-content: center;
        align-items: end;
        min-height: 160px;
        min-width: 160px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
}
