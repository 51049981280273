.about-block {
    padding: 34px 0;
    background-color: #f0f8dc;

    @include for-size(desktop-view) {
        padding: 33px 0;
    }

    ol,
    ul {
      padding-left: 2rem;
    }
    
    ol,
    ul,
    dl {
      margin-top: 0;
      margin-bottom: 1rem;
    }

    h2 {
        @include fontsize(24px);
        color: $dark;
        font-family: $general-sans-medium;
        font-weight: 500;
        text-align: center;
        margin-bottom: 16px;
    }

    > h2 {
        @include for-size(tablet-view) {
            display: none;
        }
    }

    .left-block {
        margin-bottom: 24px;

        @include for-size(tablet-view) {
            margin-bottom: 0;
            width: 50%;
            flex: none;
        }

        @include for-size(laptop-view) {
            padding-left: 8.8%;
            width: 48%;
        }

        h2 {
            display: none;
            @include for-size(tablet-view) {
                text-align: left;
                display: block;
                margin-bottom: 10px;
            }

            @include for-size(laptop-view) {
                @include fontsize(30px);
                margin-bottom: 30px;
            }
        }
    }

    .right-block {
        img {
            position: relative;
            left: -30px;

            @include for-size(tablet-view) {
                position: static;
                max-width: 665px;
                width: 100%;
            }
        }
    }

    p {
        @include fontsize(14px);
        margin-bottom: 24px;
        line-height: 1.5;

        @include for-size(laptop-view) {
            @include fontsize(18px);
            margin-bottom: 50px;
            line-height: 2;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.about-wrap {
    @include for-size(tablet-view) {
        display: flex;
        align-items: center;
    }
}
