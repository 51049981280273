.footer-wrapper {
    margin-bottom: 30px;

    @include for-size(laptop-view) {
        display: flex;
        justify-content: space-between;
    }

    .left-block {
        display: flex;
        flex-direction: column-reverse;

        @include for-size(tablet-view) {
            flex-direction: column;
        }
    }

    .right-block {
        padding-bottom: 42px;
    }

    .social-listing {
        /* display: flex;
        justify-content: center;
        align-items: center; */
        display: block;
        text-align: right;
        list-style: none;

        li {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }

        a {
            display: inline-block;
        }

        i {
            height: 42px;
            width: 42px;
            display: block;
            transition: all 0.5s;
        }
    }

    h4,
    .h4 {
        font-weight: bold;
        font-size: calculateRem(16px);
        font-family: $general-sans-semibold;
    }

    .follow-bank {
        margin-right: 20px;
        text-align: center;
        margin-bottom: 16px;
        /*     display: block;
        text-align: center;
        width: max-content;
        color: $dark;
        @include fontsize(16px);
        font-family: $general-sans-semibold;
        cursor: default;
        pointer-events: none;
        */

        @include for-size(laptop-view) {
            //margin-bottom: 78px;
            text-align: right;
        } 
    }

    .footer-navbar {
        margin-bottom: 68px;

        @include for-size(laptop-view) {
            display: flex;
            justify-content: space-between;
        }

        .menu {
            list-style: none;

            @include for-size(tablet-view) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }

            @include for-size(laptop-view) {
                justify-content: start;
            }
        }

        li {
            margin-bottom: 20px;
            white-space: nowrap;

            &:last-child {
                margin-bottom: 0;
            }

            @include for-size(tablet-view) {
                margin: 0 10px 10px;
            }

            @include for-size(desktop-view) {
                margin: 0 16px;
            }
        }

        a {
            color: $dark;
            @include fontsize(16px);
            font-family: $general-sans-semibold;

            &:hover {
                color: #f49d1a;
            }
        }
    }
}

.icon-facebook {
    background: url(../images/facebook.png) 0 0 no-repeat;
    background-size: cover;
}

.icon-instagram {
    background: url(../images/instagram.png) 0 0 no-repeat;
    background-size: cover;
}

.icon-twitter {
    background: url(../images/twitter.png) 0 0 no-repeat;
    background-size: cover;
}

.footer-box-wrapper {
    @include for-size(tablet-view) {
        display: flex;
        flex-wrap: wrap;
    }

    @include for-size(tabletLarge-view) {
        margin-bottom: 30px;
    }

    @include for-size(laptop-view) {
        flex-wrap: nowrap;
    }
}

.footer-box {
    margin-bottom: 42px;
    //width: 100%;
    text-align: center;
    display: none;

    @include for-size(tabletLarge-view) {
        display: block;
        //width: calc(33.33% - 40px);
        //margin: 0 20px;
        text-align: left;
    }

    &.logo {
        display: flex;
        //width: max-content;
        //margin: 0 auto;
        margin-bottom: 42px;
        flex-direction: row;

        @include for-size(tablet-view) {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        @include for-size(laptop-view) {
            //margin-right: 8.2%;
            flex: none;
            //margin-left: 36px;
        }
    }

    .location {
        @extend %location;
    }
}

%location {
    color: #454545;
    @include fontsize(12px);
    line-height: 1.5;
    font-style: normal;
    text-align: left;

    @include for-size(tablet-view) {
        text-align: center;
    }

    br {
        @include for-size(tabletLarge-view) {
            display: block;
        }
    }
}

.footer-logo {
    text-align: center;
    /* display: block;
    width: max-content;
    flex: none;*/
    margin-right: 12px; 

    @include for-size(tablet-view) {
        margin: 0 0 15px;
    }
}

.footer-wrapper,
.footer-menu {
    list-style: none;

    ul {
        list-style: none;
    }

    li {
        margin-bottom: 7px;
    }

    a {
        @include fontsize(16px);
        font-family: $general-sans-semibold;
        color: $dark;

        @include for-size(tabletLarge-view) {
            font-family: $general-sans-regular;
        }

        &:hover {
            color: #9a7004;
        }
    }

    p {
        @extend %location;
    }
}

@include builder("column", "footer-box") {
    @extend .footer-box;

    &.logo {
        @extend .logo;
    }

    &.social-listing {
        @extend .social-listing;
        margin-left: auto;

        [data-content-type="buttons"] {
            text-align: center;

            @include media-breakpoint-up(lg) {
                text-align: right;
            }

            [data-content-type="button-item"] {
                margin-right: 20px;

               /*  &:last-child {
                    margin-right: 0;
                } */
            }
        }

        .pagebuilder-button-link {
            &::before {
                content: "";
                display: inline-block;
                vertical-align: middle;
                width: 42px;
                height: 42px;
            }

            &[href*="facebook"] {
                &::before {
                    @extend .icon-facebook;
                }
            }

            &[href*="instagram"] {
                &::before {
                    @extend .icon-instagram;
                }
            }

            &[href*="twitter"] {
                &::before {
                    @extend .icon-twitter;
                }
            }

            span {
                display: none;
            }
        }
    }
}
