.blog-block {
    margin-bottom: 30px;
    position: relative;

    @include for-size(laptop-view) {
        margin-bottom: 88px;
    }

    .container {
        padding-right: 0;
    }

    h1 {
        @include fontsize(22px);
        margin-bottom: 20px;
        font-weight: 400;
        text-align: center;

        @include for-size(laptop-view) {
            @include fontsize(30px);
            margin-bottom: 30px;
        }
    }

    &.privacy-policy {
        .blog-wrap {
            &::before {
                top: 37px;
                height: calc(100% - 37px);
            }
        }
    }
}

.blog-wrap {
    @include for-size(tabletLarge-view) {
        display: flex;
        margin-top: 23px;
    }

    &::before {
        @include for-size(tabletLarge-view) {
            content: '';
            position: absolute;
            top: 91px;
            width: calc(100% + 241px);
            left: -100%;
            height: calc(100% - 91px);
            background-color: $white;
        }
    }

    .left-block {
        @include for-size(tabletLarge-view) {
            width: 284px;
            flex: none;
            background-color:  $white;
            margin-right: 47px;
            position: sticky;
            top: 0;
            left: 0;
            height: 100vh;
        }
    }

    .right-block {
        padding-right: 20px;
        @include for-size(tabletLarge-view) {
            width: 100%;
        }

        @include for-size(laptop-view) {
            padding-right: 35px;
        }

        h1 {
            color: $dark;
            @include fontsize(22px);
            font-family: $general-sans-medium;
            margin: 31px 0;
            margin-top: 14px;
            font-weight: 500;
            text-align: left;
            line-height: 1.5;
        }
        strong {
            margin: 31px 0;
            display: block;
            text-transform: uppercase;
            @include fontsize(16px);
            line-height: 1.7;
        }

        h3 {
            color: $dark;
            @include fontsize(22px);
            font-family: $general-sans-medium;
            margin: 31px 0;
            margin-top: 14px;
            font-weight: 500;
            text-align: left;
            line-height: 1.5;
        }
        ul {
            list-style: none;
        }
        p {
            line-height: 1.8;
        }

        &.space {
            p {
                margin-bottom: 25px;
                max-width: 1086px;
            }
        }

        li {
            line-height: 1.7;
            @include fontsize(16px);

            a {
                font-weight: 500;
                font-family: $general-sans-medium;
                color: $dark;
                transition: all 0.5s;

                &:hover {
                    color: #F49D1A;
                }
            }
        }

        h4 {
            @include fontsize(16px);
            font-weight: 600;
            margin: 31px 0;
            line-height: 1.7;
        }
    }

    .accordian-header {
        color: #101010;
        @include fontsize(16px);
        font-family: $general-sans-medium;
        display: block;
        padding: 17px 16px;
        position: relative;
        background-color: $white;

        @include for-size(tabletLarge-view) {
            display: none;
        }
        &::before {
            content: '';
            position: absolute;
            top: 14px;
            right: 16px;
            width: 24px;
            height: 24px;
            background: url(../images/accordian-btn.png) 0 0 no-repeat;
            background-size: cover;
        }
    }

    .image-wrapper {
        max-height: 310px;

        img {
            width: 100%;
            height: 100%;
            min-height: 192px;
            object-fit: cover;
            display: block;
        }
    }

    .contact-list {
        margin-bottom: 30px;
        list-style: none;
    }

    .social-listing {
        justify-content: start;
    }
}

.accordian-blog-list {
    list-style: none;
    display: none;
    padding: 0 16px;
    background-color: $white;
    @include for-size(tabletLarge-view) {
        display: block;
        padding: 14px 0;
    }

    li {
        margin-bottom: 7px;

        @include for-size(tabletLarge-view) {
            margin-bottom: 19px;
        }

        &.active {
            a {
                color: #F49D1A;
                font-weight: 600;
            }
        }
    }

    a {
        display: block;
        @include fontsize(16px);
        color: #101010;

        @include for-size(desktop-view) {
            @include fontsize(20px);
        }

        &:hover {
            color: #F49D1A;
            font-weight: 600;
        }
    }
}

.doubt-block {
    margin-bottom: 33px;

    h4 {
        margin: 0;
        color: $dark;
        margin-bottom: 5px !important;
        @include fontsize(16px);
        font-family: $general-sans-medium;
    }

    a {
        color: $dark;
        @include fontsize(16px);
        text-decoration: underline;

        &:hover {
            color: #F49D1A;
        }
    }
}

.contact-listing-text {
    color: $dark;
    @include fontsize(16px);
    font-family: $general-sans-medium;
    font-weight: 500;
    display: block;
    margin-bottom: 20px;
}

.social-listing {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;

    li {
        margin-right: 20px;

        a {
            display: inline-block;
        }
    }

    i {
        height: 42px;
        width: 42px;
        display: block;
        transition: all 0.5s;

        &.icon-facebook {
            background: url(../images/facebook.png) 0 0 no-repeat;
            background-size: cover;
        }

        &.icon-instagram {
            background: url(../images/instagram.png) 0 0 no-repeat;
            background-size: cover;
        }

        &.icon-twitter {
            background: url(../images/twitter.png) 0 0 no-repeat;
            background-size: cover;
        }
    }
}