.two-column {
    @include for-size(tablet-view) {
        display: flex;
    }

    .left-block {
        @include for-size(tablet-view) {
            width: 50%;
        }

        img {
            display: block;
            width: 100%;
            min-height: 296px;
            object-fit: cover;

            @include for-size(tablet-view) {
                height: 100%;
                min-height: auto;
            }
        }
    }

    .right-block {
        background-color: #212121;
        color:  $white;
        padding: 20px 15px 90px;

        @include for-size(tablet-view) {
            width: 50%;
            padding: 55px 20px;
            display: flex;
            align-items: center;
        }

        @include for-size(laptop-view) {
            padding: 50px 20px;
        }

        @include for-size(desktop-view) {
            padding: 0;
            padding: 64px 0;
        }

        .content {
            @include for-size(desktop-view) {
                padding: 40px 70px 40px 79px;
                max-width: 720px;
            }
        }

        p {
            @include fontsize(14px);
            margin-bottom: 24px;
            line-height: 1.5;

            @include for-size(laptop-view) {
                @include fontsize(18px);
                line-height: 1.8;
                letter-spacing: .2px;
                margin-bottom: 38px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}