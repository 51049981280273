.popup-wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .popup {
        padding: 28px 26px;
        max-width: 360px;
        margin: 0 auto;
        background-color:  $white;
        position: relative;
        @include for-size(tablet-view){
            max-width: 500px;
        }
        .cross-btn {
            position: absolute;
            width: max-content;
            top: 16px;
            right: 19px;
            background-color: transparent;
            border: none;
        }
        h2 {
            color: $dark;
            @include fontsize(20px);
            line-height: 1.5;
            font-weight: 400;
            margin-bottom: 21px;
            strong {
                font-weight: 500;
                font-family:  $general-sans-medium;
                display: block;
            }
        }
        input {
            color: #515151;
            @include fontsize(14px);
            padding: 18px 19px;
            border: 1px solid $dark;
            width: 100%;
            margin-bottom: 9px;
        }
        .btn {
            background-color: $yellow;
            border: 1px solid transparent;
            color: $dark;
            @include fontsize(20px);
            padding: 15px;
            margin-bottom: 21px;
            transition: all 0.5s;
            display: block;
            width: 100%;
            &:hover {
                border-color: $dark;
                background-color: transparent;
            }
        }
        .social_media {
            border-top: 1px dashed #D4D4D4;
            padding-top: 20px;
            h3 {
                @include fontsize(14px);
                text-align: center;
                margin-bottom: 20px;
            }
        }
        .social_links {
            list-style: none;
            display: flex;
            justify-content: center;
            align-self: center;
            margin-bottom: 15px;
            li{
                &:first-child {
                    margin-right: 19px;
                }
            }
        }
        .register_account {
            background-color: #F4F4F4;
            text-align: center;
            padding: 20px 15px;
            p {
                @include fontsize(14px);
                font-family:  $satoshi-regular;
            }
            a {
                font-family: $satoshi-medium;
                font-weight: 700;
                color: $dark;
                &:hover {
                    color: #F49D1A;
                }
            }
        }
    }
}