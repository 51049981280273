.product-category {
    margin-bottom: 40px;

    @include for-size(tabletLarge-view) {
        margin-bottom: 50px;
    }

    .container {
        padding-right: 0;
    }

    .slide {
        position: relative;
        overflow: hidden;
        text-align: center;
        img {
            width: 100%;
            display: block;
            transition: all 0.5s;
        }
        h2 {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            text-align: center;
            padding: 0 8px 10px;
            color:  $white;
            @include fontsize(16px);
            font-weight: 600;
            text-transform: uppercase;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, $dark 100%);
            height: 88px;
            display: flex;
            justify-content: center;
            align-items: end;
        }
    }
}