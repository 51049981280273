@mixin for-size($size) {
    @if $size==tablet-view {
        @media (min-width: 768px) {
            @content;
        }
    } @else if $size==tabletLarge-view {
        @media (min-width: 992px) {
            @content;
        }
    } @else if $size==laptop-view {
        @media (min-width: 1200px) {
            @content;
        }
    } @else if $size==laptopLarge-view {
        @media (min-width: 1300px) {
            @content;
        }
    } @else if $size==desktop-view {
        @media (min-width: 1440px) {
            @content;
        }
    } @else if $size==large-desktop-view {
        @media (min-width: 1600px) {
            @content;
        }
    }
}

//  Mixin for after and before
@mixin after($width, $height) {
    content: "";
    width: $width;
    height: $height;
}

//font size
@function calculateRem($size) {
    $remSize: $size / 16px;
    @return #{$remSize}rem;
}

@mixin fontsize($size) {
    font-size: calculateRem($size);
}

//Single side border
@mixin border-top($border-width, $border-style, $border-color) {
    border-top: $border-width $border-style $border-color;
}

@mixin border-right($border-width, $border-style, $border-color) {
    border-right: $border-width $border-style $border-color;
}

@mixin border-bottom($border-width, $border-style, $border-color) {
    border-bottom: $border-width $border-style $border-color;
}

@mixin border-left($border-width, $border-style, $border-color) {
    border-left: $border-width $border-style $border-color;
}

@mixin builderBody() {
    #html-body {
        @content;
    }
}

@mixin builder($element, $class, $type: "content-type") {
    @include builderBody() {
        @include element($element, $class, $type) {
            @content;
        }
    }
}

@mixin type($element, $type: "content-type", $e: "&") {
    #{$e}[data-#{$type}="#{$element}"] {
        @content;
    }
}

@mixin element($element, $class, $type: "content-type") {
    [data-#{$type}="#{$element}"] {
        &.#{$class} {
            @content;
        }
    }
}
