.cart-header {
    background-color: $yellow;
    padding: 19px 0;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo {
        width: 126px;

        @include for-size(laptop-view) {
            width: 152px;
        }
    }

    button {
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        @include fontsize(16px);
        font-family: $general-sans-medium;

        svg {
            margin-right: 7px;
        }
    }
}

.login-block {
    padding: 54px 0 76px;

    @include for-size(tabletLarge-view) {
        padding: 111px 0 152px;
    }

    .container {
        max-width: 618px;
        margin: 0 auto;
        padding: 0 9px;

        @include for-size(tabletLarge-view) {
            padding: 0;
        }
    }

    h1 {
        color: $dark;
        @include fontsize(24px);
        font-family: $general-sans-medium;
        text-align: center;
        font-weight: 500;
        max-width: 300px;
        margin: 0 auto 25px;
        line-height: 1.4;

        @include for-size(tabletLarge-view) {
            @include fontsize(32px);
            max-width: none;
            margin-bottom: 8px;
        }
    }

    p {
        text-align: center;
        max-width: 330px;
        margin: 0 auto 38px;
        line-height: 1.5;

        @include for-size(tabletLarge-view) {
            @include fontsize(20px);
            max-width: none;
            margin-bottom: 27px;
        }
    }
}

.login-form {
    background-color: $white;
    padding: 23px 13px;
    @include for-size(tabletLarge-view) {
        padding: 57px 101px 40px;
    }

    .field,
    .input-block {
        margin-bottom: 20px;
        &:nth-child(2) {
            margin-bottom: 12px;
        }
    }
    label {
        @include fontsize(14px);
        font-weight: 600;
        display: block;
        margin-bottom: 9px;
    }
    textarea,
    input {
        @include fontsize(14px);
        color: #515151;
        border: 1px solid $dark;
        width: 100%;
        padding: 18px 19px;
    }
    a {
        text-decoration: underline;
        @include fontsize(14px);
        color: #515151;
        &:hover {
            color: #f49d1a;
        }
    }
    .forget-password {
        margin-bottom: 22px;
        display: block;
        &:hover {
            border-color: $dark;
            background-color: transparent;
        }
    }
    .register {
        text-align: center;
        @include fontsize(16px);
        color: $dark;
        display: block;
        a {
            font-weight: 600;
        }
    }

    .action.submit,
    .btn {
        @include fontsize(20px);
        color: $dark;
        background-color: $yellow;
        border: 1px solid transparent;
        transition: all 0.5s;
        display: block;
        width: 100%;
        padding: 15px 20px;
        margin-bottom: 22px;
        &:hover {
            border-color: $dark;
            background-color: transparent;
        }
    }
}
