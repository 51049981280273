.product-detail {
    min-height: 100vh;
    overflow-y: auto;
    background-color: #ffffff;
    @include for-size(tabletLarge-view) {
        min-height: auto;
    }
}

.product-view-block {
    display: flex;
    flex-direction: column;

    @include for-size(tabletLarge-view) {
        padding-top: 24px;
        flex-direction: row;
    }

    @include for-size(laptop-view) {
        justify-content: space-between;
    }

    .left-block {
        text-align: center;
        position: relative;
        order: 3;

        @include for-size(tabletLarge-view) {
            width: 33.33%;
            text-align: left;
            order: 1;
        }

        @include for-size(laptop-view) {
            width: 30.5%;
        }

        .rating-name {
            display: none;

            @include for-size(tabletLarge-view) {
                display: block;
            }
        }
    }

    .rating-block {
        padding: 8px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        @include for-size(tabletLarge-view) {
            padding: 0;
            justify-content: start;
            margin-bottom: 11px;
        }

        .rating-summary {
            margin-bottom: 0;
        }

        .product-reviews-summary {
            display: flex;
            align-items: center;
            justify-content: center;

            @include for-size(tabletLarge-view) {
                justify-content: start;
            }
        }

        .rating-summary,
        .product-rating {
            margin-bottom: 0;
            margin-right: 10px;
        }

        .rating-quantity {
            display: none;

            @include for-size(tabletLarge-view) {
                display: block;
                @include fontsize(14px);
            }
        }

        .rating-summary {
            .rating-result {
                &::before {
                    letter-spacing: 4px;
                }
                span {
                    &::before {
                        letter-spacing: 4px;
                    }
                }
            }
        }

        .reviews-actions,
        .rating-count {
            @include fontsize(14px);
            color: $dark;

            a {
                color: $dark;
            }

            @include for-size(tabletLarge-view) {
                margin-left: 4px;
            }
        }
    }

    .middle-block {
        margin-bottom: 28px;

        @include for-size(tabletLarge-view) {
            order: 2;
            width: 33.33%;
        }

        @include for-size(laptop-view) {
            width: 20.7%;
        }

        .rating-name {
            @include for-size(tabletLarge-view) {
                display: none;
            }

            h1,
            h2 {
                text-align: center;
            }
        }
    }

    .right-block {
        margin-bottom: 28px;

        @include for-size(tabletLarge-view) {
            order: 3;
            width: 33.33%;
        }

        @include for-size(laptop-view) {
            width: 23.5%;
        }
    }

    .quantity-wrap {
        display: flex;
        align-items: center;
        padding: 0 14px;
        margin-bottom: 30px;

        @include for-size(tabletLarge-view) {
            display: block;
            padding: 0;
            margin-bottom: 12px;
        }

        .quantity-text {
            color: $dark;
            @include fontsize(12px);
            font-family: $general-sans-medium;
            margin-right: 10px;

            @include for-size(tabletLarge-view) {
                margin-bottom: 5px;
                display: block;
            }
        }

        .quantity-block {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 110px;
            border: 1px solid $dark;
            border-radius: 2px;
            height: 40px;

            button {
                background-color: transparent;
                border: none;
                height: 30px;
                width: 30px;
                flex: none;
                color: $dark;
                @include fontsize(16px);
                font-weight: 600;
            }

            input {
                text-align: center;
            }
        }
    }

    .add-cart-btn {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $green;
        color: $white;
        @include fontsize(16px);
        padding: 13px 10px;
        font-family: $general-sans-medium;
        z-index: 2;
        border: 1px solid transparent;
        transition: all 0.5s;

        @include for-size(tabletLarge-view) {
            position: static;
            margin-bottom: 41px;
        }

        svg {
            margin-right: 10px;
            transition: all 0.5s;
        }

        &::before {
            content: "";
            @extend .icon-minicart;
            margin-right: 10px;
            width: 24px;
            height: 24px;
            background-size: contain;
            filter: invert(1);
        }

        &:hover {
            color: $dark;
            border-color: $dark;
            background-color: $white;

            svg {
                filter: invert(100%);
            }

            &::before {
                filter: invert(0);
            }
        }
    }
    .email-wrap {
        background-color: #fff9df;
        padding: 16px;
        color: #59543d;
        @include fontsize(12px);
        p {
            line-height: 1.33;
            @include fontsize(12px);
        }
        a {
            color: #59543d;
            text-decoration: underline;
            &:hover {
                color: $pink;
            }
        }
    }
}

.rating-name {
    margin-bottom: 17px;

    h1,
    h2 {
        font-weight: 500;
        color: $dark;
        @include fontsize(22px);
        font-family: $general-sans-medium;

        @include for-size(desktop-view) {
            @include fontsize(30px);
        }
    }
}

.icon-star {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(../images/star.png) 0 0 no-repeat;
    background-size: cover;

    &.null {
        background: url(../images/star-null.png) 0 0 no-repeat;
    }
}

.product-info-listing {
    list-style: none;
    text-align: left;

    @include for-size(tabletLarge-view) {
        display: block;
    }

    li {
        display: flex;
        position: relative;
        margin-bottom: 25px;

        @include for-size(desktop-view) {
            margin-bottom: 37px;
        }

        &:last-child {
            margin-bottom: 39px;
        }

        .icon-wrap {
            width: 32px;
            margin-right: 14px;
            flex: none;
            text-align: center;
        }

        .content-text {
            color: $dark;
            font-family: $general-sans-semibold;
            @include fontsize(16px);
            font-weight: 600;
            display: block;
        }

        .about-product {
            color: $dark;
            @include fontsize(16px);
        }
    }

    i {
        display: inline-block;

        &.icon-globe {
            width: 28px;
            height: 34px;
            background: url(../images/icon-globe.png);
            background-size: cover;
        }

        &.icon-grapes {
            width: 32px;
            height: 32px;
            background: url(../images/icon-grapes.png);
            background-size: cover;
        }

        &.icon-glass {
            width: 16px;
            height: 33px;
            background: url(../images/icon-glass.png);
            background-size: cover;
        }

        &.icon-percentage {
            width: 28px;
            height: 28px;
            background: url(../images/icon-percentage.png);
            background-size: cover;
        }

        &.icon-bottle {
            width: 14px;
            height: 45px;
            background: url(../images/icon-bottle.png);
            background-size: cover;
        }

        &.icon-cap {
            width: 32px;
            height: 20px;
            background: url(../images/icon-cap.png);
            background-size: cover;
        }
    }
}

.product-ranking-block {
    background: rgba(255, 225, 93, 0.2);
    align-items: center;
    padding: 13px 15px;
    color: $dark;
    display: flex;
    margin-bottom: 30px;

    @include for-size(tabletLarge-view) {
        display: flex;
    }

    img {
        width: 75px;
        flex: none;
        margin-right: 14px;
    }

    .ranking-content {
        text-align: left;
    }

    .rank {
        display: inline-block;
        background-color: #f49d1a;
        border-radius: 9px;
        font-family: $general-sans-semibold;
        color: $white;
        @include fontsize(16px);
        line-height: 1;
        padding: 8px 18px 4px;
        margin-bottom: 6px;
    }

    p {
        text-align: left;
        @include fontsize(16px);
        line-height: 1.5;
        margin-bottom: 0;
    }
}

.products_feature {
    justify-content: space-between;
    flex-wrap: wrap;
    color: $dark;
    list-style: none;
    padding: 28px 0;
    display: flex;
    border-top: 1px dashed $dark;

    @include for-size(tabletLarge-view) {
        display: flex;
    }

    li {
        display: flex;
        align-self: center;
        margin-bottom: 38px;
    }

    .feature-name {
        font-family: $general-sans-semibold;
        @include fontsize(14px);
        margin-right: 6px;
        min-width: 50px;
        letter-spacing: -0.4px;

        @include for-size(desktop-view) {
            @include fontsize(16px);
            min-width: 60px;
        }
    }
}

.rating-dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    background-color: #f49d1a;
    margin-right: 6px;

    @include for-size(desktop-view) {
        height: 20px;
        width: 20px;
        margin-right: 8px;
    }
}

.product-slide-wrap {
    color: $dark;
    position: relative;

    .slick-arrow {
        @include for-size(tabletLarge-view) {
            display: none !important;
        }
    }

    .slider-for {
        @include for-size(tabletLarge-view) {
            margin-bottom: 48px;
        }
    }

    .slider-nav {
        display: none;

        @include for-size(tabletLarge-view) {
            display: block;
        }

        img {
            @include for-size(tabletLarge-view) {
                width: 5.3vw;
                margin: 0 auto;
                max-width: 52px;
            }
        }
    }

    .slide {
        img {
            margin: 0 auto;
            width: 28vw;
            max-width: 150px;

            @include for-size(tabletLarge-view) {
                width: auto;
                max-width: none;
            }
        }
    }
}

.label-image {
    display: none;

    @include for-size(tabletLarge-view) {
        display: block;
        position: absolute;
        top: 20px;
        right: 33px;
        z-index: 1;
        width: 60px;
    }

    @include for-size(laptop-view) {
        width: max-content;
        left: calc(100% - 30px);
        right: auto;
        top: 0;
    }
}

.product-price-info {
    text-align: center;
    margin-bottom: 33px;

    @include for-size(tabletLarge-view) {
        text-align: left;
        margin-bottom: 25px;
    }

    .cancel-price {
        color: #696969;
        @include fontsize(14px);
        display: block;

        @include for-size(tabletLarge-view) {
            @include fontsize(16px);
        }
    }

    .current-price {
        color: #dc3535;
        display: block;
        @include fontsize(24px);
        font-family: $general-sans-semibold;
        line-height: 1.4;

        @include for-size(tabletLarge-view) {
            @include fontsize(30px);
        }

        small {
            @include fontsize(14px);

            @include for-size(tabletLarge-view) {
                @include fontsize(20px);
            }
        }
    }

    .installments {
        color: $dark;
        @include fontsize(14px);
        display: block;
        margin-bottom: 6px;

        @include for-size(tabletLarge-view) {
            @include fontsize(16px);
        }
    }

    .pay-through {
        color: #073919;
        @include fontsize(12px);
        border-radius: 43px;
        background: rgba(200, 243, 215, 0.4);
        display: block;
        margin: 0 auto;
        width: fit-content;
        padding: 10px 22px;

        @include for-size(tabletLarge-view) {
            margin: 0;
        }
    }
}

.zip-code-wrap {
    display: flex;
    margin-bottom: 33px;

    @include for-size(tabletLarge-view) {
        display: block;
    }
    
    input, select {
        color: $dark;
        @include fontsize(14px);
        padding: 15px 29px;
        border-radius: 2px;
        margin-bottom: 8px;
        border: 1px solid #8a8a8a;
    }
    .input-group {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        width: 100%;
        input{
            position: relative;
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            width: 1%;
            margin-bottom: 0 !important;
        }
        .input-group-append{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-left: -1px;
            button{
                position: relative;
                z-index: 2;
                display: inline-block;
                background: $white;
                color: $dark;
                border: 1px solid #8a8a8a;
                background-clip: padding-box;
                font-weight: bold;
                &:hover{
                    background: $green;
                    color: $white;
                }
            }
        }
    }
    .zip-wrap {
        a {
            color: #979797;
            @include fontsize(12px);
            text-decoration-line: underline;
        }
    }
}

.zip-code-text {
    color: $dark;
    margin-top: 17px;
    @include fontsize(14px);
    font-family: $general-sans-semibold;
    white-space: nowrap;
    margin-right: 15px;

    @include for-size(tabletLarge-view) {
        @include fontsize(16px);
        margin-bottom: 6px;
        display: block;
    }
}

.button-text {
    display: none;
    margin-left: 5px;
    @include for-size(tablet-view) {
        display: block;
    }
}

.attribute {
    &.overview,
    &.description {
        ol,
        ul {
            padding-left: 2rem;
        }

        ol,
        ul,
        dl {
            margin-top: 0;
            margin-bottom: 1rem;
        }

        ol ol,
        ul ul,
        ol ul,
        ul ol {
            margin-bottom: 0;
        }

        dt {
            font-weight: $dt-font-weight;
        }
    }
}

//review
.items {
    margin: 0;
    padding: 0;
    list-style: none none;
}

.product {
    .data.switch .counter {
        &:before {
            content: "(";
        }

        &:after {
            content: ")";
        }
    }
}

.review-list {
    .block-title {
        margin-bottom: 2rem;
        font-size: 1.3rem;
        display: none;
    }

    .items {
        margin-bottom: 30px;
    }

    .review-toolbar,
    .pager {
        display: none;
    }
}

.review-item {
    border-bottom: 1px solid #c9c9c9;
    margin: 0;
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
        padding: 30px 0;
    }
}

.review-title {
    flex: 1 0 100%;
    font-weight: 600;
    line-height: 1.1;
    font-size: 1.2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin: 0 0 20px;

    @include media-breakpoint-up(md) {
        margin: 0 0 30px;
    }
}

.review-ratings {
    .rating-label {
        margin-right: 35px;
    }
}

.review-content {
    margin-bottom: 20px;
}

.review-ratings,
.review-content,
.review-details {
    flex: 0 0 50%;
}

.review-details {
    margin-left: auto;
}

.review-add {
    padding: 0;
    background: none;
    @extend .login-form;

    .block-title {
        display: none;
    }

    .review-legend {
        &.legend {
            margin-bottom: 30px;
            strong {
                display: block;
                font-weight: 600;
            }
        }
    }
}

%required {
    &:after {
        content: "*";
        color: #e02b27;
        font-size: 1.2rem;
        margin: 0 0 0 5px;
    }
}

$star_color : #efcf28;

fieldset {
    .field {
        &.required,
        &._required {
            .label {
                @extend %required;
            }
        }
    }

    .fields {
        .field {
            &.required,
            &._required {
                .label {
                    @extend %required;
                }
            }
        }
    }
}

.review-field-rating {
    .control {
        margin-bottom: 48px;
        margin-top: 10px;
    }
}

.review-control-vote {
    overflow: hidden;

    &:before {
        color: #c7c7c7;
        font-family: "luma-icons";
        font-size: 30px;
        height: 30px;
        letter-spacing: 2px;
        line-height: 30px;
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        content: "\2605""\2605""\2605""\2605""\2605";
        display: block;
        position: absolute;
        z-index: 1;
    }

    input[type="radio"] {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;

        &:hover + label:before,
        &:checked + label:before {
            opacity: 1;
        }
    }

    label {
        cursor: pointer;
        display: block;
        position: absolute;

        span {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        &:before {
            color: $star_color;
            font-family: "luma-icons";
            font-size: 30px;
            height: 30px;
            letter-spacing: 2px;
            line-height: 30px;
            font-style: normal;
            font-weight: 400;
            vertical-align: top;
            -webkit-font-smoothing: antialiased;
            opacity: 0;
        }

        &:hover:before {
            opacity: 1;
        }

        &:hover ~ label:before {
            opacity: 0;
        }
    }

    .rating-5 {
        z-index: 2;

        &:before {
            content: "\2605""\2605""\2605""\2605""\2605";
        }
    }

    .rating-4 {
        z-index: 3;

        &:before {
            content: "\2605""\2605""\2605""\2605";
        }
    }

    .rating-3 {
        z-index: 4;

        &:before {
            content: "\2605""\2605""\2605";
        }
    }

    .rating-2 {
        z-index: 5;

        &:before {
            content: "\2605""\2605";
        }
    }

    .rating-1 {
        z-index: 6;

        &:before {
            content: "\2605";
        }
    }

    &:before {
        content: "\2605""\2605""\2605""\2605""\2605";
    }
}

.products-reviews-toolbar {
    position: relative;

    .toolbar-amount {
        position: relative;
        text-align: center;
    }
}

.review-toolbar {
    border-top: 1px solid #c9c9c9;
    margin: 0 0 20px;
    padding: 0;
    text-align: center;
}
