.services-block {
    display: none !important;
    overflow-x: auto;

    @include for-size(tabletLarge-view) {
        display: block !important;
        margin-bottom: 25px;
    }
    &.mobile {
        display: block;
        @include for-size(tabletLarge-view) {
            display: none;
        }
        .container {
            overflow-x: auto;
            &::-webkit-scrollbar-track {
                background-color: transparent;
              }
              
              &::-webkit-scrollbar {
                width: 0;
                height: 0;
                background-color: transparent;
              }
              
              &::-webkit-scrollbar-thumb {
                background-color: transparent;
              }
        }
        .services-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 0;
            background-color:  $white;
            width: 950px;
            margin-bottom: 44px;
            .service-block {
                display: flex;
                align-items: center;
                padding: 0 3%;
                position: relative;
                svg {
                    margin-right: 8px;
                }
                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    height: 23px;
                    width: 1px;
                    background-color: #D6D6D6;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .service_text {
                    @include fontsize(14px);
                    font-weight: 700;
                    display: block;
                    font-family: $satoshi-medium;
                }
            }
        }
    }
}

.services-wrap {
    @include for-size(tabletLarge-view) {
    /*     display: flex;
        align-items: center;
        justify-content: space-between; */
        overflow: hidden;
        padding: 12px 0;
        background-color:  $white;
    }

    .service-block {
        @include for-size(tabletLarge-view) {
            display: flex;
            align-items: center;
            padding: 0 5%;
            position: relative;
        }

        svg {
            @include for-size(tabletLarge-view) {
                margin-right: 8px;
            }

        }

        &::after {
            @include for-size(tabletLarge-view) {
                content: '';
                position: absolute;
                right: 0;
                height: 23px;
                width: 1px;
                background-color: #D6D6D6;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .searvice_text {
            @include for-size(tabletLarge-view) {
                @include fontsize(14px);
                margin-bottom: 3px;
                font-family: $satoshi-medium;
                font-weight: 700;
            }
        }

        .content {
            p {
                white-space: nowrap;
                @include fontsize(14px);
            }
        }

        p {
            @include for-size(tabletLarge-view) {
                @include fontsize(12px);
                font-family: $satoshi-regular;
            }

            @include for-size(desktop-view) {
                @include fontsize(14px);
            }

            a {
                color: $text-color;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $text-color;
                }
            }
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }
}