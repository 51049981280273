%button-offer {
    background: $green;
    height: 41px;
    width: 41px;
    line-height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 13px;
    transition: all 0.5s;
    text-align: center;
    color: $white;
    border: 0;

    @include for-size(tabletLarge-view) {
        height: 55px;
        width: 55px;
        line-height: 55px;
        border-radius: 10px;
    }

    [data-element="link_text"] {
        @extend %hide;
    }

    &::before {
        content: "";
        width: 30px;
        height: 30px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'%3E%3Cpath d='M10.8331 15.1109H18.1664M15.1109 27.3331C21.8612 27.3331 27.3331 21.8612 27.3331 15.1109C27.3331 8.36056 21.8612 2.88867 15.1109 2.88867C8.36056 2.88867 2.88867 8.36056 2.88867 15.1109C2.88867 21.8612 8.36056 27.3331 15.1109 27.3331Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3Cpath d='M15.7217 18.7777L19.3883 15.111L15.7217 11.4443' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E");
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        filter: invert(1);

        @include for-size(tabletLarge-view) {
            filter: none;
        }
    }

    &:hover {
        background-color: $text-color;
    }
}

.product-gallery {
    margin-bottom: 30px;
    position: relative;

    @include for-size(laptop-view) {
        margin-bottom: 35px;
    }

    .container {
        display: flex;
        flex-wrap: wrap;
    }

    a {
        /*    display: block;
        margin-bottom: 10px;
        position: relative;
        width: 100%;

        @include for-size(tablet-view) {
            width: 50%;
            margin: 0;
        }

        @include for-size(tabletLarge-view) {
            display: block;
            width: 25%;
        } */

        img {
            width: 100%;
            display: block;
        }
    }

    .offer-btn {
        position: absolute;
        bottom: 20px;
        right: 15px;

        a {
            @extend %button-offer;
            height: 55px;
            width: 55px;

            &::before {
                filter: none;
            }
        }
    }
}

@include builderBody() {
    .categories-slider {
        /* the slides */
        .slick-slide {
            margin: 0 14px;
        }

        /* the parent */
        .slick-list {
            margin: 0 -14px;
        }
    }

    .banner-category {
        .slick-slide {
            margin: 0 7px;
        }

        /* the parent */
        .slick-list {
            margin: 0 -7px;
        }

        .pagebuilder-slide-wrapper,
        .pagebuilder-banner-wrapper {
            /*    width: 194px;
            height: 204px; 
            transition: background-size 0.3s ease-in-out; */
            background-position: center;
            background-size: 100%;
            position: relative;
            min-height: auto;
            overflow: hidden;

            &:hover {
                /* background-size: 120%;
                transition: background-size 0.3s ease-in-out; */

                img {
                    transform: scale(1.2);
                    transition: transform 0.5s;
                }
            }

            img {
                width: 100%;
                display: block;
                transition: transform 0.5s;
            }

            h2,
            .h2 {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                text-align: center;
                padding: 0 8px 10px;
                color: #ffffff;
                font-size: 1rem;
                font-weight: 600;
                text-transform: uppercase;
                background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0) 0%,
                    #000000 100%
                );
                height: 88px;
                display: flex;
                justify-content: center;
                align-items: end;
            }
        }
    }

    .banners-home {
        .pagebuilder-column-line {
            width: auto;
        }
    }

    .banner-home {
        position: relative;
        @include media-breakpoint-up(lg) {
            margin-bottom: 35px;
        }

        a {
            display: inline;
        }

        img {
            @include media-breakpoint-up(lg) {
                width: 100%;
            }
        }

        .pagebuilder-banner-button {
            position: absolute;
            bottom: 20px;
            right: 15px;
            @extend %button-offer;
            background: $yellow;
        }
    }
}
