.product-view-block {
    &.big-product {
        .left-block {
            @include for-size(tabletLarge-view) {
                width: 33%;
            }

            @include for-size(laptop-view) {
                width: 25%;
            }

            p {
                text-align: left;
                @include fontsize(16px);
                line-height: 1.5;
                margin-bottom: 22px;

                @include for-size(laptop-view) {
                    margin-bottom: 60px;
                }
            }
        }

        .middle-block {
            @include for-size(tabletLarge-view) {
                width: 36%;
            }

            @include for-size(laptop-view) {
                width: 36.8%;
            }
        }

        .right-block {
            @include for-size(tabletLarge-view) {
                width: 28%;
            }

            @include for-size(laptop-view) {
                width: 23.5%;
            }
        }

        .ranking-content {
            p {
                margin-bottom: 0;
            }
        }

        .label-image {
            @include for-size(tabletLarge-view) {
                display: none;
            }
        }

        .product-slide-wrap {
            .slider-nav {
                @include for-size(tabletLarge-view) {
                    max-width: 350px;
                    margin: 0 auto;
                }

                img {
                    @include for-size(tabletLarge-view) {
                        width: 5.3vw;
                        margin: 0 auto;
                        max-width: 87px;
                    }
                }
            }

            .slide {
                img {
                    max-width: 270px;
                    width: 72vw;

                    @include for-size(laptop-view) {
                        width: 100%;
                        max-width: 570px;
                    }
                }
            }
        }
    }
}

.product-ranking-block {
    &.bg {
        background-color: $dark;
        color: $white;
        padding: 1px 12px;
        display: flex;
        align-items: center;

        img {
            width: 61px;
        }
    }
}
