.wide-image {
    margin-bottom: 30px;
    @include for-size(laptop-view){
        margin-bottom: 35px;
    }
    a {
        display: block;
    }
    img {
        width: 100%;
        display: block;
    }
    &.no-space {
        .container {
            padding: 0;
        }
        .breadcrumb {
            padding-left: 15px;
            @include for-size(large-desktop-view){
                padding: 0;
            }
        }
    }
}

.breadcrumbs .items,
.breadcrumb {
        display: flex;
        list-style: none;
        padding: 10px 0;
        background-color: #fbfbfb;
    li {
        @include fontsize(12px);
        font-family: $general-sans-medium;
        margin-right: 17px;
        position: relative;
        &:last-child {
            margin-right: 0;
            font-weight: 600;
            &::after{
                display: none;
            }
        }
        a {
            color: #030303;
            @include fontsize(12px);
            font-family: $general-sans-regular;
            &:hover {
                color: $pink;
            }
        }
        &::after {
            content: '/';
            position: absolute;
            top: 50%;
            right: -12px;
            transform: translateY(-50%);
            font-size: inherit;
        }
    }
}