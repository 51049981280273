.brands-block {
    padding: 51px 0 70px;

    @include for-size(tabletLarge-view) {
        padding: 31px 0 65px;
    }

    .pagebuilder-column-line,
    .brands-wrap {
        @include for-size(tabletLarge-view) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 1245px;
        }

        h5 {
            color: $dark;
            @include fontsize(13px);
            text-align: center;
            margin-bottom: 15px;
            width: 100%; //break flexbox

            @include for-size(tabletLarge-view) {
                display: none;
            }
        }

      /*   .brand-text-wrap {
            &:first-child {
                margin-bottom: 63px;

                @include for-size(tabletLarge-view) {
                    margin-bottom: 0;
                }
            }
        } */
    }

    .left-block  {
        display: flex;
        justify-content: center !important;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row !important;
        width: auto !important;
        margin-bottom: 83px;
        @include for-size(tabletLarge-view){
            margin-bottom: 0;
        }
    }

    .right-block {
        display: flex;
        justify-content: center !important;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row !important;
        width: auto !important;

        @include media-breakpoint-up(lg) {
            justify-content: flex-end !important;
        }
    }

    figure {
        margin: 0 15px;
    }
}

//Bagulho podre
img {
    &.pagebuilder-mobile-only {
        @include for-size(tabletLarge-view) {
            display: none !important;
        }
    }

    &.pagebuilder-mobile-hidden {
        display: none !important;

        @include for-size(tabletLarge-view) {
            display: inline !important;
        }
    }
}