.three-column {
    padding: 40px 0;
    text-align: center;

    @include for-size(desktop-view) {
        padding: 102px 0 120px;
    }

    h2 {
        color: $dark;
        @include fontsize(24px);
        font-family: $general-sans-medium;
        font-weight: 500;
        margin-bottom: 26px;

        @include for-size(tablet-view) {
            margin-bottom: 45px;
            @include fontsize(30px);
        }
    }
    
}

.three-column-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include for-size(tablet-view) {
        flex-direction: row;
        justify-content: space-between;
        max-width: 774px;
        margin: 0 auto;
    }
    a {
        display: block;
        margin-bottom: 49px;
        @include for-size(tablet-view){
            margin-bottom: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .image-wrapper {
        margin-bottom: 15px;
    }
    h3 {
        @include fontsize(16px);
        color: $dark;
    }
}