.brand-footer {
    background-color:  $white;
    padding: 21px 0 50px;

    .container {
        @include for-size(desktop-view) {
            display: flex;
            align-items: center;
        }
    }

    .footer-box {
        &.logo {
            @include for-size(tabletLarge-view){
                margin: 0 auto 20px;
            }
            @include for-size(desktop-view) {
                margin: 0;
                display: flex;
                flex-direction: row;
                flex: none;
                margin-right: 65px;
            }
        }

        .location {
            @include for-size(desktop-view) {
                text-align: left;
            }
        }
    }

    .footer-logo {
        @include for-size(desktop-view) {
            margin: 0;
            margin-right: 25px;
        }
    }

    .brands_wrap {
        @include for-size(desktop-view) {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
        }
    }
    .brands {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: -15px;
        a{
            margin: 15px;
        }
    }
}