// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1320px,
    xxl: 1464px,
);

$headings-font-weight: bold;

//@import "../node_modules/bootstrap/scss/bootstrap";

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/close";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../node_modules/bootstrap/scss/utilities/api";

//tem bagulho mais ridículo que isso???
@include builderBody() {
    [data-content-type="row"] {
        &[data-appearance="contained"] {
            //> [data-element="inner"] {
            @extend .container;
            //}
        }
    }
}

//ridiculo
//Make boostrap grid on magento 2 page builder
@include builderBody() {
    .pagebuilder-column-group[data-content-type="column-group"] {
        @include make-grid-columns();

        &.no-gutters {
            .pagebuilder-column-line {
                @extend .g-0;
            }
        }

        .pagebuilder-column-line {
            @include make-row();
            //width: auto;
            
            //flex-direction: row;
        }
    }

   .pagebuilder-column[data-content-type="column"] {
        @include make-col-ready();
        //@include make-col();
    } 
}

#contentarea {
    display: inline;
}

.alert {
    a {
        @extend .alert-link;
    }
}
