.contact-block {
    padding: 30px 0;
    border-top: 1px solid $dark;
    border-bottom: 1px solid $dark;

    @include for-size(laptop-view) {
        padding: 8px 0;
    }

    .contact-box {
        @include for-size(tablet-view) {
            width: calc(50% - 20px);
            margin: 20px 10px;
        }

        @include for-size(tabletLarge-view) {
            width: max-content;
        }

        i {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 42px;
            width: 44px;
            border-radius: 10px;
            flex: none;
            margin-right: 8px;
            background-color: $yellow;
            background-repeat: no-repeat;
            background-position: center;
        }

        .content_text {
            @include fontsize(14px);
            display: block;
            font-weight: 600;
        }

        a {
            @include fontsize(16px);
            line-height: 1.6;
            font-family: $general-sans-semibold;
            color: $text-color;

            &:hover {
                color: #9a7004;
            }
        }
    }

    p {
        color: #2f2f2f;
        text-align: center;
        @include fontsize(13px);
        max-width: 340px;
        margin: 0 auto;
        @include for-size(tablet-view) {
            max-width: none;
            margin: 0;
        }
    }
}

.contact-block {
    @include fontsize(14px);
    display: block;

    .pagebuilder-column-line {
        justify-content: space-between;
    }

    .pagebuilder-column {
        @extend .contact-box;
        display: flex;
        align-items: center;
        flex-direction: row !important;
        width: 100% !important;
        font-weight: 600;

        @include media-breakpoint-up(md) {
            width: auto !important;
            margin-top: 20px !important;
        }

        p {
            text-align: left;
        }
    }
}

.icon-location {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z' stroke='%23181818' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3Cpath d='M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z' stroke='%23181818' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E");
}

.icon-email {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z' stroke='%23181818' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3Cpath d='M22 6L12 13L2 6' stroke='%23181818' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E");
}

.contact-block .icon-call {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M15.0475 5C16.0242 5.19057 16.9219 5.66826 17.6256 6.37194C18.3292 7.07561 18.8069 7.97326 18.9975 8.95M15.0475 1C17.0768 1.22544 18.9691 2.13417 20.4137 3.57701C21.8584 5.01984 22.7695 6.91101 22.9975 8.94M21.9975 16.92V19.92C21.9986 20.1985 21.9416 20.4742 21.83 20.7293C21.7184 20.9845 21.5548 21.2136 21.3496 21.4019C21.1443 21.5901 20.9021 21.7335 20.6382 21.8227C20.3744 21.9119 20.0949 21.9451 19.8175 21.92C16.7403 21.5856 13.7845 20.5341 11.1875 18.85C8.77132 17.3147 6.72283 15.2662 5.18749 12.85C3.49747 10.2412 2.44573 7.27099 2.11749 4.18C2.0925 3.90347 2.12537 3.62476 2.21399 3.36162C2.30262 3.09849 2.44506 2.85669 2.63226 2.65162C2.81945 2.44655 3.0473 2.28271 3.30128 2.17052C3.55527 2.05833 3.82983 2.00026 4.10749 2H7.10749C7.5928 1.99522 8.06328 2.16708 8.43125 2.48353C8.79922 2.79999 9.03957 3.23945 9.10749 3.72C9.23411 4.68007 9.46894 5.62273 9.80749 6.53C9.94204 6.88792 9.97115 7.27691 9.8914 7.65088C9.81164 8.02485 9.62635 8.36811 9.35749 8.64L8.08749 9.91C9.51105 12.4135 11.5839 14.4864 14.0875 15.91L15.3575 14.64C15.6294 14.3711 15.9726 14.1858 16.3466 14.1061C16.7206 14.0263 17.1096 14.0555 17.4675 14.19C18.3748 14.5286 19.3174 14.7634 20.2775 14.89C20.7633 14.9585 21.2069 15.2032 21.524 15.5775C21.8412 15.9518 22.0097 16.4296 21.9975 16.92Z' stroke='%23181818' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E");
}

.icon-chat {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z' stroke='%23181818' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E");
}

.contact-wrapper {
    @include for-size(tablet-view) {
        display: flex;
        margin: -15px;
        flex-wrap: wrap;
        margin-bottom: 0;
    }

    @include for-size(tabletLarge-view) {
        justify-content: space-between;
    }

    @include for-size(laptop-view) {
        margin: 0;
    }
}
