.offer-block {
    margin-bottom: 32px;

    @include for-size(laptop-view) {
        margin-bottom: 35px;
    }

    .offer-wrapper {
        @include for-size(tablet-view) {
            display: flex;
            margin: 0 -15px;
        }
    }

    .offer {
        position: relative;
        overflow: hidden;
        display: block;

        @include for-size(tablet-view) {
            width: calc(50% - 30px);
            margin: 0 15px;
        }

        &:first-child {
            margin-bottom: 5px;

            @include for-size(tablet-view) {
                margin-bottom: 0;
            }
        }

        img {
            transition: all 0.5s;
            width: 100%;
            display: block;
        }

        .offer-btn {
            position: absolute;
            bottom: 15px;
            right: 15px;
            background: $yellow;
            height: 41px;
            width: 41px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 13px;

            @include for-size(tabletLarge-view) {
                height: 55px;
                width: 55px;
                border-radius: 10px;
            }
            &:hover {
                background-color: $green;
            }
        }
    }
}