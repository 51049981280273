.feature-slider {
    margin-bottom: 7px;

    @include for-size(laptop-view) {
        margin-bottom: 35px;
    }

    .container {
        padding-right: 0;
    }
    .icon-arrow{
        top: 37%;
    }
}

.feature-wrapper {
    @include for-size(tablet-view) {
        display: flex;
    }

    .left-block {
        padding: 0;
        margin-bottom: 15px;
        display: block;

        @include for-size(tablet-view) {
            margin-bottom: 0;
            width: 24.3%;
            max-width: 283px;
            background-color: $pink;
            display: flex;
            align-items: center;
            flex: none;
            margin-right: 20px;
        }

        img {
            width: 100%;
            display: block;
        }

        &.yellow {
            padding-right: 15px;

            @include for-size(tablet-view) {
                background-color: $orange;
                margin-right: 15px;
                margin-left: 20px;
            }

            @include for-size(large-desktop-view) {
                padding-right: 0;
                margin-right: 0;
            }
        }
    }

    .right-block {
        padding: 0;
        @include for-size(tablet-view) {
            width: 73.1%;
        }

        @include for-size(laptop-view) {
            width: calc(100% - 303px);
        }
    }

    &.reverse {
     /*    @include for-size(tablet-view) {
            flex-direction: row-reverse;
        } */

        .pagebuilder-column-line {
            @include for-size(tablet-view) {
                flex-direction: row-reverse;
            }
        }
    }
}


@include builderBody() {
    .feature-slider {
        @extend .feature-slider;
    }

    .feature-wrapper {
        @extend .feature-wrapper;
    }
}