.banner {
    margin-bottom: 21px;

    @include for-size(tablet-view) {
        margin-bottom: 15px;
    }

    .container {
        @include media-breakpoint-down(lg) {
            padding: 0;
            position: relative;
        }
    }

    .icon-arrow {
        display: none;

        @include for-size(tablet-view) {
            display: block;
            top: 50%;
        }
    }

    img {
        width: 100%;
        display: block;
    }

    .slick-slide,
    .swiper-slide {
        padding-bottom: 35px;
        @include for-size(tablet-view) {
            padding-bottom: 0;
        }
    }
    
    .swiper-pagination {
        bottom: 8px;
        @include for-size(tablet-view) {
            bottom: 18px;
        }
    }
}

.swiper-container2 {
    overflow-x: hidden;
}

//slick
.slick-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: all;
}

.slick-dots {
    @extend .swiper-pagination;
    list-style: none;
    bottom: 8px;
    @include for-size(tablet-view) {
        bottom: 18px;
    }

    li {
        button {
            @extend .swiper-pagination-bullet;
            margin-right: 7px !important;
        }

        &.slick-active {
            button {
                @extend .swiper-pagination-bullet-active;
            }
        }

        &:last-child {
            button {
                margin-right: 0 !important;
            }
        }
    }
}
